import seedrandom from 'seedrandom'
import { createNoise2D } from 'simplex-noise'

// calculate vh
document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`)
window.addEventListener('resize', () => {
  document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`)
})

const canvas = document.getElementById('c') as HTMLCanvasElement
const ctx = canvas.getContext('2d')

const params = Object.fromEntries(window.location.search.split(/[?&]/).slice(1).map(qstr => qstr.split('=')))

// 1, 2, 3, 4, 5, 6, 8, 9, 10, 12, 15, 18, 20, 24, 30, 36, 40, 45, 60, 72, 90, 120, 180, 360
const pixelSize = parseInt(params.res) || 20
const sizeParams = params.size ? params.size.split('x').map(v => Math.round(v / pixelSize)) : []
const size = sizeParams[0] && sizeParams[1] ? sizeParams : [Math.round(2880 / pixelSize), Math.round(1800 / pixelSize)]
canvas.width = size[0] * pixelSize
canvas.height = size[1] * pixelSize
// canvas.style.width = size[0] * pixelSize / 3 + 'px'
// canvas.style.height = size[1] * pixelSize / 3 + 'px'

const generateSeed = () => ('0000000000000000' + Math.floor(Math.random() * parseInt('zzzzzzzzzzzzzzzz', 36)).toString(36)).slice(-16)
generate(generateSeed())
canvas.addEventListener('click', () => {
  generate(generateSeed())
})

// window.setInterval(() => {
//   generate(Math.round(Math.random() * 1e16).toString(36))
// }, 60000 * 4 / 140)

function generate (seed) {
  document.getElementById('seed').textContent = seed

  const random = seedrandom(seed)
  const noise = createNoise2D(random)

  const colorModels = [
    // ['FBC', 'B27', '043', '6F9'],
    // ['dc', '93', '9fc9', '1e2c'],
    // ['1f', 'bc', '5a5b', 'b278'],
    // ['fb2', '171', '278', 'ffa'],
    [
      ('000' + Math.floor(random() * parseInt('FFFFFF', 16)).toString(16)).slice(-3),
      ('000' + Math.floor(random() * parseInt('FFFFFF', 16)).toString(16)).slice(-3),
      ('000' + Math.floor(random() * parseInt('FFFFFF', 16)).toString(16)).slice(-3),
      ('000' + Math.floor(random() * parseInt('FFFFFF', 16)).toString(16)).slice(-3)
    ]
  ]

  const colorModel = colorModels[Math.floor(random() * colorModels.length)]
  const zonesSize = [200 * random(), 200 * random()]

  const lineLayers = [
    Array(size[0]).fill(0).map(() => random() > 0.5),
    Array(size[0]).fill(0).map(() => random() > 0.5),
    Array(size[0]).fill(0).map(() => random() > 0.5),
    Array(size[0]).fill(0).map(() => random() > 0.5),
    Array(size[0]).fill(0).map(() => random() > 0.5),
    Array(size[0]).fill(0).map(() => random() > 0.5)
  ]

  for (let y = 0; y < size[1]; y++) {
    const oldLineLayers = lineLayers

    for (let x = 0; x < size[0]; x++) {
      const lefts = oldLineLayers.map(layer => layer[(size[0] + x - 1) % size[0]])
      const centers = oldLineLayers.map(layer => layer[x])
      const rights = oldLineLayers.map(layer => layer[(x + 1) % size[0]])

      if (noise(x / zonesSize[0], y / zonesSize[1]) + 0.1 * (random() - 0.5) > 0) {
        lineLayers.forEach((_, i) => { lineLayers[i][x] = [0, 2].includes([lefts[i], centers[i], rights[i]].filter(v => v).length) })
      } else {
        lineLayers.forEach((_, i) => { lineLayers[i][x] = [1, 2].includes([lefts[i], centers[i], rights[i]].filter(v => v).length) })
      }

      // const color = (lineLayers[0][x] ? '#F8D' : '#129') + (lineLayers[1][x] ? '413' : 'EDF') // + (lineLayers[2][x] ? '58' : 'AB')
      // const color = lineLayers[0][x] || lineLayers[1][x] ? '#000' : '#FFF'
      const color = '#' +
        ((lineLayers[0][x] || lineLayers[1][x]) && lineLayers[2][x] ? colorModel[0] : colorModel[1]) +
        ((lineLayers[3][x] || lineLayers[4][x]) && lineLayers[5][x] ? colorModel[2] : colorModel[3])

      // let color = random() > 0.5 ? '#123' : '#FED'
      // color += random() > 0.5 ? '123' : 'FED'
      // color += random() > 0.5 ? '00' : 'FF'

      ctx.fillStyle = color
      ctx.fillRect(x * pixelSize, y * pixelSize, pixelSize, pixelSize)

      // ctx.fillStyle = 'rgb(' + (noise(x / 50, y / 50) + 1) * 60 + ', 0, 0)'
      // ctx.fillRect(x * pixelSize, y * pixelSize, pixelSize, pixelSize)
    }
  }

  // console.log({ seed, zonesSize, colorModel })

  const link = document.getElementById('download-link') as HTMLAnchorElement
  link.download = 'automaton-' + seed + '.png'
  link.href = canvas.toDataURL()
}
